import { Action } from '../actions/actions'

const INITIAL_STATE = {
  Reports: [],
  TotalCount: 0,
}

function ReportsReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.GotReportRules:
      return {
        ...state,
        Reports: action.data.Data.map((item: any) => {
          if (item.ReportRule[item.ReportRule.Type]) {
            return {
              ...item.ReportRule[item.ReportRule.Type],
              Type: item.ReportRule.Type,
              Id: Math.random(),
              LastReport: item.LastReport,
              ReportFiles: item.ReportFiles,
            }
          }
        }),
        TotalCount: action?.data?.TotalCount,
      }

    case Action.DeleteReportRule:
      return {
        ...state,
        Reports: state.Reports.filter((item: any) => item.ScheduleRuleName !== action.data.ScheduleRuleName),
      }

    default:
      return state
  }
}

export default ReportsReducer
