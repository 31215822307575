import React, { useEffect, useState } from 'react'
import { IRightbar } from './rightbar-types'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { fetchGet, processError } from '../../utils/fetch-utils'
import { useDispatch } from 'react-redux'
import { AppTable } from '@t4b/core'
import { buildTableData, buildTableHeadersFromArray } from '../../utils/table-utils'
import { ITableColumn } from '../../utils/table-types'

function transformFilter(filter: any) {
  return {
    gateway: filter.Gateway,
    platform: filter.Platform,
  }
}

function transformItem(item: any) {
  return {
    id: item.Id,
  }
}

const tableHeader: ITableColumn[] = [
  { name: 'Login', show: true },
  { name: 'Direction', show: true },
  { name: 'Volume', show: true },
]

const VolumeTransferHistoryRightbar: React.FC<IRightbar> = ({ data: { item, filter } }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState<any>({ Distribution: [] })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchGet('/api/volumeTransfer/history/details', transformFilter(filter), transformItem(item))
        setData(fetchedData)
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchData()
  }, [dispatch, filter, item])

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="volume-transfer-history.rightbar" />
      </Card.Header>
      <Card.Body>
        <AppTable
          tableHeader={buildTableHeadersFromArray(tableHeader, 'volume-transfer-history')}
          tableData={buildTableData(data?.Distribution, tableHeader, null)}
        />
      </Card.Body>
    </Card>
  )
}

export default VolumeTransferHistoryRightbar
