import { Dispatch } from 'redux'
import { throwSuccessMessage } from '../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { Action } from './actions'

function onlyFetchConsolidationRules(params: any) {
  const url = new URL('/api/consolidation/rules', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }
  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchConsolidationRules(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchConsolidationRules(params)
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchConsolidationHistory(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/consolidation/history', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('page', params.Page)
    url.searchParams.set('count', params.Count)
    if (params.field.length && params.by !== 'none') {
      url.searchParams.set('sort', params.field)
      url.searchParams.set('by', params.by)
    }

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: Action.GotConsolidationHistory, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchConsolidationRulesDelete(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/consolidation/rulesDelete', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions({ Id: params.Id }))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        } else {
          throwSuccessMessage('Successfully')
          return onlyFetchConsolidationRules(params)
        }
      })
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchConsolidationRulesUpdate(arg: any) {
  const { action, rule, params } = arg
  const url = new URL(`/api/consolidation/rules${action === 'clone' ? 'Add' : `${action[0].toUpperCase()}${action.slice(1)}`}`, document.location.origin)

  url.searchParams.set('gateway', params.Gateway)
  if (action === 'clone') {
    rule.Id = 0
  }
  rule.Lps = optionsToStrings(rule.Lps)

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(rule))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        } else {
          throwSuccessMessage('Successfully')
          return onlyFetchConsolidationRules(params)
        }
      })
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}
