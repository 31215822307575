import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFile, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { downloadRegenerateReportFile, downloadReportAllFile, downloadReportFile } from '../../../redux/actions/reports-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

export const GeneratedFiles = memo(({ item, platformName }: any) => {
  const dispatch = useDispatch()
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const fetchReport = (elem: any) => {
    dispatch(
      downloadReportFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
        fileName: elem.Name,
      }),
    )
  }

  const fetchReportRegenerate = (elem: any) => {
    dispatch(
      downloadRegenerateReportFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
        fileName: elem.Name,
      }),
    )
  }

  const fetchReportAll = () => {
    dispatch(
      downloadReportAllFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
      }),
    )
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  return (
    <>
      {item?.ReportFiles?.map((item: { Name: string; CanRegenerate: boolean }) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <div className="reportNameDownload mb-2">
              <span className="align-items-center">
                <FontAwesomeIcon icon={faFile} className="mr-2 icon-faFile" />
                {item.Name}
              </span>
            </div>
            <span>
              {item.CanRegenerate && (
                <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, 'Regenerate report')}>
                  <FontAwesomeIcon icon={faRepeat} className="cursor-pointer icon-faDownload mr-2" onClick={() => fetchReportRegenerate(item)} />
                </OverlayTrigger>
              )}
              <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, 'Download report')}>
                <FontAwesomeIcon icon={faDownload} className="cursor-pointer icon-faDownload" onClick={() => fetchReport(item)} />
              </OverlayTrigger>
            </span>
          </div>
        )
      })}
      <Button className="t4b-bg-dark-button mt-3" onClick={fetchReportAll}>
        <FormattedMessage id="DownloadAll" />
      </Button>
    </>
  )
})
