import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Day, ScheduleType } from '../entity/configuration'
import { buildControlsExtTwoPerLine, mselectInput, selectInput, sselectInput, timeInput, timespanInput } from '../utils/controls'
import { buildMultiselectOptionsFromArray, buildMultiselectOptionsFromEnum, buildOptionsWithEveryday, isEqualArrays, isEverydayOption, optionsToStrings } from '../utils/multiselect-utils'
import { useTranslate } from '../hooks/useTranslate'
import { AppAccordion } from '@t4b/core/lib'

interface IAppSchedule {
  state: any
  touched: any
  setTouched: any
  errors: any
  isValid: any
  flagSchedule?: string
  type?: string

  setState(state: any): any
}

function scheduleTypesConverter(translate: any) {
  return function (option: string) {
    return translate(`schedule-type.${option}`)
  }
}

const AppSchedule = forwardRef(({ state, setState, touched, setTouched, errors, isValid, flagSchedule, type }: IAppSchedule, ref: any) => {
  const translate = useTranslate()

  const alwaysInputs = buildControlsExtTwoPerLine(
    [
      sselectInput('ScheduleType', buildMultiselectOptionsFromArray(flagSchedule === 'clone' ? [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range, ScheduleType.Custom] : [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]))
        .optionZindex(false)
        .converter(scheduleTypesConverter(translate))
        .disabled(type === 'add'),
    ],
    state,
    setState,
    'schedule',
    touched,
    setTouched,
    errors,
  )

  const setOrderTypesState = (stateOne: any) => {
    const all = 'Everyday'

    if (
      stateOne.Days?.find(isEverydayOption) ||
      isEqualArrays(
        optionsToStrings(stateOne.Days)
          .filter(option => option !== all)
          .sort(),
        Object.keys(Day)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setState({
        ...stateOne,
        Days: buildMultiselectOptionsFromArray([all]),
      })
      return
    }
    setState(stateOne)
  }

  const periodicInputs = buildControlsExtTwoPerLine(
    [
      sselectInput('ScheduleType', buildMultiselectOptionsFromArray(flagSchedule === 'clone' ? [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range, ScheduleType.Custom] : [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]))
        .optionZindex(false)
        .converter(scheduleTypesConverter(translate))
        .disabled(type === 'add'),
      mselectInput('Days', buildOptionsWithEveryday(buildMultiselectOptionsFromEnum(Day), state.Days)).stateSetup(setOrderTypesState),
      timespanInput('Start'),
      timespanInput('Duration'),
    ],
    state,
    setState,
    'schedule',
    touched,
    setTouched,
    errors,
  )

  const rangeInputs = buildControlsExtTwoPerLine(
    [
      sselectInput('ScheduleType', buildMultiselectOptionsFromArray(flagSchedule === 'clone' ? [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range, ScheduleType.Custom] : [ScheduleType.Always, ScheduleType.Periodic, ScheduleType.Range]))
        .optionZindex(false)
        .converter(scheduleTypesConverter(translate))
        .disabled(type === 'add'),
      timeInput('Begin', 'none'),
      timeInput('End', 'none'),
    ],
    state,
    setState,
    'schedule',
    touched,
    setTouched,
    errors,
  )

  let content = null
  switch (state.ScheduleType.value || state.ScheduleType) {
    case ScheduleType.Always:
      content = alwaysInputs
      break
    case ScheduleType.Periodic:
      content = periodicInputs
      break
    case ScheduleType.Range:
      content = rangeInputs
      break
    case ScheduleType.Custom:
      content = alwaysInputs
      break
  }

  return (
    <AppAccordion
      item={{
        title: <FormattedMessage id="schedule.title" />,
        item: content,
      }}
      ref={ref}
      render={ref.current}
    />
  )
})

export default AppSchedule
