import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { DropCopySettingsEntity } from '../../entity/drop-copy-entity'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchDropCopySettingsUpdate } from '../../redux/actions/execution/drop-copy-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput, timespanInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const DropCopySettingsRightbar: React.FC<IRightbar> = React.memo(({ data: { item, params } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new DropCopySettingsEntity(item), DropCopySettingsEntity.schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    dispatch(hideRightBar())
    dispatch(fetchDropCopySettingsUpdate(params, inputState))
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="drop-copy.settings.rightbar" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('SocketAcceptPort'),
            checkboxInput('UseLocalTime'),
            timespanInput('StartTime'),
            timespanInput('EndTime'),
            checkboxInput('SSLEnable'),
            textInput('FileStorePath'),
            textInput('FileLogPath'),
          ],
          inputState,
          setInputState,
          'drop-copy.settings',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default DropCopySettingsRightbar
