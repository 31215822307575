import classNames from 'classnames'
import React, { useRef } from 'react'
import { IQuote, IQuoteData } from './quotes-types'

function getBidDiff(source1: IQuoteData, source2: IQuoteData | undefined | null): any[] {
  return source1.OrderBook.Bid.map((item: IQuote | null, index: number) => {
    const diff: any = {
      VolumeDiff: null,
      PriceDiff: null,
    }

    if (item === null) {
      return null
    }

    if (item && source2?.OrderBook.Bid[index]) {
      diff.VolumeDiff = +(item.Volume - source2.OrderBook.Bid[index].Volume).toFixed(2)
      diff.PriceDiff = +(item.Price - source2.OrderBook.Bid[index].Price).toFixed(5)
    }

    return {
      ...item,
      ...diff,
    }
  })
}

function getAskDiff(source1: IQuoteData, source2: IQuoteData | undefined | null): any[] {
  let source2quotes: any = null
  if (source2) {
    source2quotes = Array.from(source2.OrderBook.Ask)
  }

  return Array.from(source1.OrderBook.Ask).map((item: IQuote, index: number) => {
    const diff: any = {
      VolumeDiff: null,
      PriceDiff: null,
    }

    if (item === null) {
      return null
    }

    if (item && source2quotes && source2quotes[index]) {
      diff.VolumeDiff = +(item.Volume - source2quotes[index].Volume).toFixed(5)
      diff.PriceDiff = +(item.Price - source2quotes[index].Price).toFixed(5)
    }

    return {
      ...item,
      ...diff,
    }
  })
}

function getVolumeBidClasses(value: number | null | undefined): string {
  if (value == null) {
    return ''
  }

  if (value >= 0) {
    return 'text-success font-700 border-bottom border-dark'
  }
  return 'text-danger font-700 border-bottom border-dark'
}

function getAskClasses(value: number | null | undefined): string {
  if (value == null) {
    return ''
  }

  if (value <= 0) {
    return 'text-success font-700 border-bottom border-dark'
  }
  return 'text-danger font-700 border-bottom border-dark'
}

function buildBid(quoteStorage: Map<string, IQuoteData>, selection: Set<string>, me: string, depth: number) {
  let quotes: IQuoteData | undefined = quoteStorage.get(me)
  let otherQuotes = null

  if (quotes === undefined) {
    return null
  }

  if (selection.size === 2 && selection.has(me)) {
    const otherKey = Array.from(selection.keys()).find((item: string) => item !== me) || ''
    otherQuotes = quoteStorage.get(otherKey)
  }

  const data = getBidDiff(quotes, otherQuotes)

  const tds = []
  for (let i = 0; i < depth; i++) {
    const item = data[i]
    tds.push(
      <tr style={{ height: '26px' }} className={classNames(i === 0 && 'bestQ-bid-row')}>
        <td className={getVolumeBidClasses(item?.VolumeDiff)}>{item?.VolumeDiff}</td>
        <td className="border-bottom border-dark">{item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume} ` : `${item?.Volume / 1000000} M`}</td>
        <td className="border-bottom border-dark color-bid">{item?.Price ?? 'N/A'}</td>
        <td className={getVolumeBidClasses(item?.PriceDiff)}>{item?.PriceDiff}</td>
      </tr>,
    )
  }
  return tds
}

function buildAsk(quoteStorage: Map<string, IQuoteData>, selection: Set<string>, me: string, depth: number) {
  let quotes: IQuoteData | undefined = quoteStorage.get(me)
  let otherQuotes = null

  if (quotes === undefined) {
    return null
  }

  if (selection.size === 2 && selection.has(me)) {
    const otherKey = Array.from(selection.keys()).find((item: string) => item !== me) || ''
    otherQuotes = quoteStorage.get(otherKey)
  }

  let notEnough = 0
  let data = getAskDiff(quotes, otherQuotes).slice(0, depth).reverse()
  if (data.length < depth) {
    notEnough = depth - data.length
  }

  const tds = []

  for (let i = 0; i < notEnough; i++) {
    const item: any = null
    tds.push(
      <tr style={{ height: '26px' }}>
        <td className={getVolumeBidClasses(item?.VolumeDiff)}>{item?.VolumeDiff}</td>
        <td className="border-bottom border-dark">{item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume}` : `${item?.Volume / 1000000} M`}</td>
        <td className="border-bottom border-dark color-ask">{item?.Price ?? 'N/A'}</td>
        <td className={getAskClasses(item?.PriceDiff)}>{item?.PriceDiff}</td>
      </tr>,
    )
  }

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    tds.push(
      <tr style={{ height: '26px' }} className={classNames(i === data.length - 1 && 'bestQ-ask-row')}>
        <td className={getVolumeBidClasses(item?.VolumeDiff)}>{item?.VolumeDiff}</td>
        <td className={item?.Volume ? 'border-bottom border-dark' : ''}>
          {item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume}` : `${item?.Volume / 1000000} M`}
        </td>
        <td className={item?.Price ? 'border-bottom border-dark color-ask' : ''}>{item?.Price ?? 'N/A'}</td>
        <td className={getAskClasses(item?.PriceDiff)}>{item?.PriceDiff}</td>
      </tr>,
    )
  }

  return tds
}

interface IQuoteTable {
  quotesStorage: Map<string, IQuoteData>
  selection: any
  me: string
  depth: number
  expand?: boolean
}

const QuotesTable: React.FC<IQuoteTable> = ({ quotesStorage, selection, me, depth, expand }) => {
  const max = useRef({ askLength: 0, bidLength: 0 })
  let askDepth = depth
  let bidDepth = depth

  if (expand) {
    const askLength = quotesStorage.get(me)?.OrderBook.Ask.length ?? 0
    const bidLength = quotesStorage.get(me)?.OrderBook.Bid.length ?? 0

    if (askLength >= max.current.askLength) {
      max.current.askLength = askLength
      askDepth = askLength
    }

    if (bidLength >= max.current.bidLength) {
      max.current.bidLength = bidLength
      bidDepth = bidLength
    }
  }

  return (
    <table
      style={{
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        textAlign: 'center',
      }}
    >
      <colgroup>
        <col style={{ width: '100px' }} />
        <col style={{ width: '100px' }} />
        <col style={{ width: '100px' }} />
        <col style={{ width: '100px' }} />
      </colgroup>
      <tbody>
        {buildAsk(quotesStorage, selection, me, askDepth)}
        {buildBid(quotesStorage, selection, me, bidDepth)}
      </tbody>
    </table>
  )
}

export default QuotesTable
