import React from 'react'
import { CSSTransition } from 'react-transition-group'

interface IAnimatedContainerProps {
  loading: boolean
}

const AnimatedContainer: React.FC<IAnimatedContainerProps> = ({ loading, children }) => {
  return (
    <CSSTransition in={loading} timeout={300} classNames="loading" appear={true}>
      <div>{children}</div>
    </CSSTransition>
  )
}

export default AnimatedContainer
